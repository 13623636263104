<template lang="pug">
.row.justify-content-center.my-5
  .col-lg-6.col-xl-4.mb-4
    .error-text-box.text-center
      svg(viewbox='0 0 600 200')
        symbol#s-text
          text(text-anchor='middle' x='50%' y='50%' dy='.35em') 404!
        use.text(xlink:href='#s-text')
        use.text(xlink:href='#s-text')
        use.text(xlink:href='#s-text')
        use.text(xlink:href='#s-text')
        use.text(xlink:href='#s-text')
    .text-center
      h3.mt-0.mb-2 噢豁，這個網址不對勁！
      p.text-muted.mb-3
        sapn 會來到這裡的原因是因為網址與定義的規則不符合
        br
        span 請重新確認您要前往的功能網址
        br
        br
        span 若是舊版本的後台網址，請以新版本的後台網址進行更新
      router-link.btn.btn-success.waves-effect.waves-light(to='/') 回到資訊管理平台首頁
</template>

<script>
import appConfig from "../../../../app.config";

/**
 * Error-404-alt component
 */
export default {
};
</script>
